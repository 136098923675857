// create a method that will call an api and return data at the start of the application
// it must trigger a re-render when the data is fetched, so make it a hook

import React from "react"
import { branch } from "../App"
import { datadogRum } from "@datadog/browser-rum";

// Path: src/data/experiments.ts

export const experiments: string[] = [];

export function useExperiments() {
    const url = branch !== "development" ? "/api/experiments" : "https://grades-experiments.ben754444.workers.dev/experiments"

    const [data, setData] = React.useState<string[]>([])
    React.useEffect(() => {
        if(experiments.length > 0) {
            setData(experiments)
            return;
        }
        fetch(url, {
            headers: {
                "X-User-Id": localStorage.getItem('uid') ?? "",
                "X-Project": window.location.hostname
            }
        })
            .then(response => response.json())
            .then(data => setData(data.data))
    }, []);
    data.forEach((experiment: string) => {
        experiments.push(experiment)
        const bucket = experiment.split("_").at(-1)
        const name = experiment.split("_").slice(0, 2).join("_")
        datadogRum.addFeatureFlagEvaluation(name, bucket)
    });
    return (data) as string[]
}
