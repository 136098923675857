import { SyntheticEvent, useEffect, useRef, useState } from 'react'
import EntryBox from './components/EntryBox'
import { Data } from './data/validate'
import { CommonQuestions } from './components/CommonQuestions';
import ClipboardModal from './components/ClipboardModal';
import ExitModal from './components/ExitModal';
import Library from './components/Library';
import IosShareIcon from '@mui/icons-material/IosShare';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import SaveModal from './components/SaveModal';
import { useExperiments } from './data/experiments';
import { datadogRum } from "@datadog/browser-rum";
import { HelpOutline } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

export const branch: string = "master" ?? "development";
export const commit: string = "acff34f" ?? "development";

function App() {
    const [data, setData] = useState<Data[]>([])
    const [clipboardModalOpen, setClipboardModalOpen] = useState(false)
    const [exitModalOpen, setExitModalOpen] = useState(false)
    const [saveModalOpen, setSaveModalOpen] = useState(false)
    const [changesMade, setChangesMade] = useState(false)
    const [convertError, setConvertError] = useState("")
    const [enteredMark, setEnteredMark] = useState(0)
    const experiments = useExperiments()
    const scaleUpValue = screen.width < 640 ? 92 * 2 : 96
    const constantAdd = screen.width < 640 ? 7.5 * 2 : 3.75
    const dataRef = useRef<Data[]>()
    dataRef.current = data

    useEffect(() => {
        datadogRum.startView({ name: location.pathname });
    }, [location.pathname]);




    async function addData(data_inp: Data | Data[]) {
        setChangesMade(true)
        if (Array.isArray(data_inp)) {
            setData([...(dataRef.current ?? []), ...data_inp].sort((a, b) => b.year - a.year))
            dataRef.current = [...(dataRef.current ?? []), ...data_inp]
            window.history.pushState({}, '', `/${dataRef.current![0].subject.replaceAll(" ", "-").toLowerCase()}-${dataRef.current![0].unit}?data=${encodeURIComponent(btoa(JSON.stringify(dataRef.current)))}`)
        } else {
            setData([...(dataRef.current ?? []), data_inp].sort((a, b) => b.year - a.year))
            dataRef.current = [...(dataRef.current ?? []), data_inp]
            window.history.pushState({}, '', `/${dataRef.current![0].subject.replaceAll(" ", "-").toLowerCase()}-${dataRef.current![0].unit}?data=${encodeURIComponent(btoa(JSON.stringify(dataRef.current)))}`)
        }
        document.title = `${dataRef.current![0].subject} U${dataRef.current![0].unit}`
    }
    const colours = ["bg-red-500", "bg-orange-500", "bg-yellow-500", "bg-green-500", "bg-blue-500", "bg-indigo-500", "bg-purple-500", "bg-pink-500"]

    async function updateData(data: Data[]) {
        setData(data)
        setChangesMade(false)
        const subject = data[0]?.subject?.replaceAll(" ", "-")?.toLowerCase()
        let path;
        if (subject) {
            path = `/${subject}-${data[0].unit}`
            document.title = `${data[0].subject} U${data[0].unit}`
        } else {
            document.title = "Grade Boundaries Analyser"
        }
        console.log(path)
        window.history.pushState({}, '', `${path || "../"}?data=${encodeURIComponent(btoa(JSON.stringify(data)))}`)
    }

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const data = params.get('data')
        if (data) {
            try {
                const decoded = JSON.parse(atob(decodeURIComponent(data!)))
                if (decoded) {
                    setData(decoded)
                }
            } catch (e) {
                console.error(e)
                window.history.pushState({}, '', `/`)
            }
        }
    }, [])

    const url = new URL(window.location.href)

    useEffect(() => {
        setEnteredMark(0) //clear entered mark when visualisation changes
        if (url.pathname === "/") {
            document.title = "Grade Boundaries Tool"
        }
        switch (url.pathname) {
            case "/further-mathematics-1-sample":
                fetch("/sample_fm1.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/further-mathematics-2-sample":
                fetch("/sample_fm2.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/further-mathematics-3-sample":
                fetch("/sample_fm3.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/further-mathematics-4-sample":
                fetch("/sample_fm4.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/further-mathematics-6-sample":
                fetch("/sample_fm6.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/computer-science-3-sample":
                fetch("/sample_cs3.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/computer-science-4-sample":
                fetch("/sample_cs4.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
            case "/computer-science-5-sample":
                fetch("/sample_cs5.json").then(res => {
                    res.json().then(data => {
                        addData(data)
                        setChangesMade(false)
                    })
                })
                break;
        }
    }, [url.pathname])

    const navigate = useNavigate()

    // listen to paste event

    //TODO ADDING MORE THAN 2 DATA SETS ON MOBILE BREAKS EVERYTHING (MAYBNE TO DO WITH SWITCHING TABS ??)
    // TODO TEXT WRAP OVERFLOWS ON MOBILE AND CREATES SCROLL WHEEL
    return (
        <div className="relative h-max min-h-full">
            <div className="pb-20">
                <ClipboardModal isOpen={clipboardModalOpen} closeModal={() => setClipboardModalOpen(false)}></ClipboardModal>
                <ExitModal updateData={updateData} isOpen={exitModalOpen} closeModal={() => setExitModalOpen(false)}></ExitModal>
                <SaveModal isOpen={saveModalOpen} closeModal={() => setSaveModalOpen(false)}></SaveModal>
                <h1 className="text-4xl text-center xl:text-left">Grade Boundaries Tool</h1>
                <p className="mb-2">This tool allows you to compare grade boundaries across different years for WJEC/EDUQAS qualifications.</p>
                {data.length > 0 ? (
                    <div className="pt-2">
                        <div className="flex justify-between">
                            <h1 className="text-2xl font-bold text-center xl:text-left">{data[0].level} {data[0].session} {data[0].subject} UNIT {data[0].unit} ({data[0].code})</h1>
                            <div className='flex justify-center gap-2'>
                                <button disabled={saveModalOpen} className="disabled:bg-neutral-800 disabled:hover:bg-neutral-800 flex flex-row font-semibold justify-center items-center text-center bg-indigo-600 hover:bg-indigo-700 py-2 px-4 rounded-lg" onClick={() => {
                                    setSaveModalOpen(true)
                                    //setSeenExitRescueUpsell(false) //unset this if they look at the save modal as an extra reminder
                                    //alert("Copied share URL to clipboard. You can save this URL or send it to a friend to share your data")
                                }}>
                                    <p className="pr-1">Save</p>
                                    <SaveIcon fontSize='small'></SaveIcon>
                                </button>

                                <button disabled={clipboardModalOpen} className="flex flex-row font-semibold justify-center items-center text-center bg-neutral-800 hover:bg-indigo-800 w-10 h-10 rounded-3xl" onClick={() => {
                                    navigator.clipboard.writeText(window.location.href)
                                    setClipboardModalOpen(true)
                                    //alert("Copied share URL to clipboard. You can save this URL or send it to a friend to share your data")
                                }}>
                                    <IosShareIcon fontSize='small'></IosShareIcon>
                                </button>

                                <button disabled={exitModalOpen} className="flex flex-row font-semibold justify-center items-center text-center bg-neutral-800 hover:bg-red-800 w-10 h-10 rounded-3xl" onClick={() => {
                                    if (changesMade) {
                                        setExitModalOpen(true)
                                    } else {
                                        updateData([])
                                    }
                                    //alert("Copied share URL to clipboard. You can save this URL or send it to a friend to share your data")
                                }}>
                                    <CloseIcon fontSize='small'></CloseIcon>
                                </button>
                            </div>

                        </div>
                        <div className="flex pt-2">
                            {data.map((data_map, i) => (
                                <div className={`flex flex-row items-center justify-center text-xl font-bold text-center xl:text-left mb-5 ${i !== 0 && "ml-2"} bg-gray-500 hover:bg-red-500 cursor-pointer rounded-lg p-2`} onClick={async () => {
                                    const new_data = data.filter((data_itr) => data_itr.year !== data_map.year)
                                    await updateData(new_data)
                                    setChangesMade(true)
                                }}>
                                    <p>{data_map.year}</p>
                                    <CloseIcon />
                                </div>
                            ))}
                        </div>
                        {data.some((data) => [2023, 2022, 2021, 2020].includes(data.year)) && (
                            <p className="text-sm text-center xl:text-left pb-5 text-gray-500">One or more of the selected years were impacted by the COVID-19 pandemic. Grade boundaries are likely to be anomalous.</p>
                        )}
                        <p className="text-lg text-center xl:text-left pb-5">Maximum Raw Mark: <span className="font-bold">{data[0].max_raw}</span></p>

                        <table className="table-auto border-collapse border-4 border-gray-800">
                            <thead>
                                <tr>
                                    <th className="border border-gray-800 px-4 py-2 text-white"></th>
                                    {data.map((data) => (
                                        <th className="border-x-4 border-t-4 border-gray-800 px-4 py-2 text-white" colSpan={3}>{data.year}</th>
                                    ))}
                                </tr>
                            </thead>

                            <thead>
                                <tr>
                                    <th className="border border-gray-800 px-4 py-2 text-white">Grade</th>
                                    {data.map(() => (
                                        <>
                                            <th className="border border-l-4 border-gray-800 px-4 py-2 text-white">UMS</th>
                                            <th className="border border-gray-800 px-4 py-2 text-white">Raw</th>
                                            <th className="border border-r-4 border-gray-800 px-4 py-2 text-white">Raw %</th>
                                        </>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data[0].grades.map((grade) => {
                                    const highest_raw = data.map((data_itr) => data_itr.grades.find((grade_itr) => grade_itr.grade === grade.grade)?.raw).reduce((a, b) => Math.max(a as number, b as number))
                                    const lowest_raw = data.map((data_itr) => data_itr.grades.find((grade_itr) => grade_itr.grade === grade.grade)?.raw).reduce((a, b) => Math.min(a as number, b as number))
                                    return (
                                        <tr key={grade.grade}>
                                            <td className="border font-bold border-gray-800 px-4 py-2 text-white">{grade.grade}</td>
                                            {data.map((data_itr) => {
                                                const data_grade = data_itr.grades.find((grade_itr) => grade_itr.grade === grade.grade)

                                                return (
                                                    <>
                                                        <td className={`border border-l-4 border-gray-800 px-4 py-2 text-white`}>{data_grade?.ums}</td>
                                                        <td className={`border border-gray-800 px-4 py-2 text-white ${highest_raw !== lowest_raw && (data_grade?.raw === highest_raw && "bg-red-500" || data_grade?.raw === lowest_raw && "bg-green-500")} `}>{data_grade?.raw}</td>
                                                        <td className={`border border-r-4 border-gray-800 px-4 py-2 text-white`}>{(Math.round(data_grade?.raw! / data_itr.max_raw * 100 * 10) / 10).toFixed(1)}</td>
                                                    </>
                                                )
                                            })}
                                        </tr>
                                    )
                                })}
                            </tbody>


                            <tbody>

                            </tbody>
                        </table>

                        {experiments.includes("2024-05_mark-conversion_1") && (
                            <>
                                <h2 className="text-2xl font-bold text-center xl:text-left pt-5 pb-2">Mark Conversion</h2>
                                <form onSubmit={(e: SyntheticEvent<HTMLFormElement, SubmitEvent>) => {
                                    e.preventDefault();
                                    setConvertError("");
                                    const form = new FormData(e.target as HTMLFormElement);
                                    const year = form.get('year');
                                    const raw = form.get('raw');
                                    const ums = form.get('ums');
                                    const year_data = data.find((data) => data.year === parseInt(year as string));
                                    switch (e.nativeEvent.submitter?.id) {
                                        case "to_ums":
                                            const raw_to_ums = year_data?.raw_to_ums[parseInt(raw as string)];
                                            if (raw_to_ums) {
                                                (e.target as HTMLFormElement).ums.value = raw_to_ums.toString();
                                                setEnteredMark(parseInt(raw as string));
                                            } else {
                                                setConvertError("Invalid raw mark");
                                            }
                                            break;
                                        case "to_raw":
                                            const ums_to_raw = data.find((data) => data.year === parseInt(year as string))?.ums_to_raw[parseInt(ums as string)];
                                            if (ums_to_raw) {
                                                const max_ums = year_data?.raw_to_ums[year_data.max_raw];
                                                setEnteredMark(ums_to_raw);
                                                if (max_ums === parseInt(ums as string)) {
                                                    setConvertError("Warning: MAX UMS. Exact raw mark cannot be determined");
                                                    (e.target as HTMLFormElement).raw.value = `${ums_to_raw.toString()}`;
                                                } else {
                                                    (e.target as HTMLFormElement).raw.value = ums_to_raw.toString();
                                                }
                                            } else {
                                                setConvertError("Invalid UMS mark");
                                            }
                                            break;
                                    }
                                }}>
                                    <div className="flex flex-col w-min">
                                        <select name='year' className="w-32 text-black text-center rounded-md py-1 px-4 self-center">
                                            {data.map((data) => (
                                                <option value={data.year}>{data.year}</option>
                                            ))}
                                        </select>
                                        <div className="flex flex-row items-center gap-4">
                                            <div className='flex flex-col items-center'>
                                                <h3 className="text-lg font-bold">Raw</h3>
                                                <input name="raw" className="w-28 text-center text-black text-lg rounded-md py-4 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" maxLength={3} max={data[0].max_raw} min={0} type="number" placeholder="000" />
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <button id="to_ums" type="submit" className="mt-7 bg-indigo-600 hover:bg-indigo-900 rounded-lg text-xl px-4 text-white">&gt;</button>
                                                <button id="to_raw" type="submit" className="bg-indigo-600 hover:bg-indigo-900 rounded-lg text-xl px-4 text-white">&lt;</button>
                                            </div>
                                            <div className='flex flex-col items-center'>
                                                <h3 className="text-lg font-bold">UMS</h3>
                                                <input onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        e.preventDefault();
                                                        const form = e.currentTarget.form!;
                                                        const button = form.querySelector("#to_raw") as HTMLButtonElement;
                                                        button.click();
                                                    }
                                                }} name="ums" className="w-28 text-center text-black text-lg rounded-md py-4 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" maxLength={3} max={data[0].raw_to_ums[data[0].max_raw]} min={0} type="number" placeholder="000" />
                                            </div>
                                        </div>
                                        <p className="text-red-600 self-center pt-1">{convertError}</p>
                                    </div>
                                </form>
                            </>
                        )}


                        <h2 className="text-2xl font-bold text-center xl:text-left pt-5">Raw Mark Distribution</h2>
                        <p className="pb-1">The following visualisation shows how marks are distributed across the grade boundaries. <span className="font-extrabold">It does not show the proportion of people who achieved each grade</span>, that remains constant.</p>
                        <p className="pb-1">Approximately 10% of students achieve an A* in each subject. An A* that is shifted to the right suggests most scores in this exam were lower than usual, so possibly a harder paper.</p>
                        <div>
                            <div title="Your mark" className={`absolute ${enteredMark === 0 ? "bg-transparent" : "bg-white"} w-1`} style={{ height: data.length * 80, left: `${((((data[0].max_raw - enteredMark) / data[0].max_raw)) * (scaleUpValue)) + constantAdd}%`, transition: 'left 0.5s' }}></div>
                            {data.map((data) => (
                                <>
                                    <div className="sm:w-full w-[200%] flex flex-row justify-center items-center">
                                        <div className="sm:w-full w-[200%] h-20 bg-gray-800 flex flex-row justify-center items-center pr-1">
                                            <div className="sm:w-[4%] w-[8%] flex justify-center pr-1">
                                                <p className="font-extrabold lg:text-lg md:text-base text-sm break-all leading-4">{data.year}</p>
                                            </div>
                                            {data.grades.map((grade) => (
                                                <div className={`${colours[data.grades.indexOf(grade)]} flex flex-row justify-center items-center h-[90%]`} style={{ width: `${((data.grades[data.grades.indexOf(grade) - 1] || { raw: data.max_raw }).raw - grade.raw) / data.max_raw * 100}%` }} >
                                                    <div className="flex flex-col items-center">
                                                        <p className="text-white lg:text-base text-xs">{grade.grade}</p>
                                                        <p className="text-white lg:text-base text-xs">{(data.grades[data.grades.indexOf(grade) - 1] || { raw: data.max_raw }).raw - grade.raw} Raw</p>
                                                        <p className="text-white lg:text-base text-xs">{Math.round(((data.grades[data.grades.indexOf(grade) - 1] || { raw: data.max_raw }).raw - grade.raw) / data.max_raw * 100)}%</p>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </>
                            ))}
                        </div>

                        <div className='flex flex-row items-baseline gap-2'>
                            <h1 className="text-2xl font-bold pt-5">Add more data</h1>

                            {experiments.includes("2024-06_help-page_1") && (
                                <button className="flex items-center gap-1 mb-2 bg-zinc-900 hover:bg-zinc-800 rounded-lg px-4 py-2 text-white" onClick={() => {
                                    navigate("/help")
                                }}>
                                    <HelpOutline fontSize="small" />
                                    <p>Help</p>
                                </button>
                            )}
                        </div>


                        <EntryBox addData={addData} data={data} />


                        <hr className="mt-8 border-2 rounded-lg"></hr>

                        <CommonQuestions></CommonQuestions>

                        <p className="pb-2"></p>

                    </div>
                ) : (
                    <div className="flex flex-col items-center self-auto justify-between h-full pb-4 text-center">
                        <div className="flex flex-col items-center self-auto justify-normal text-center">
                            <p className="text-2xl font-bold">Waiting for data...</p>
                            <p>Paste the <b>entire</b> page from the WJEC Portal UMS converter of the desired paper</p>
                            {
                                //<p className="text-lg">Press CTRL+V anywhere on this page to paste data</p> 
                            }
                            <EntryBox addData={addData} data={data} />
                            {experiments.includes("2024-06_help-page_1") && (
                                <button className="flex items-center gap-1 mt-2 bg-zinc-900 hover:bg-zinc-800 rounded-lg px-4 py-2 text-white" onClick={() => {
                                    navigate("/help")
                                }}>
                                    <HelpOutline fontSize="small" />
                                    <p>Help</p>
                                </button>
                            )}
                            <div>
                                <h2 className="text-2xl font-bold mt-5">Library</h2>
                                <Library addData={addData} setChangesMade={setChangesMade}></Library>
                            </div>

                        </div>
                        <div className="absolute bottom-10 w-full">
                            <div className="flex flex-col justify-center items-center mt-auto text-center">
                                <p className="text-sm bottom-0 text-gray-500">This tool only supports data from the <a target="_blank" className='text-blue-500 hover:text-blue-600 visited:text-purple-500' href="https://services.portal.wjec.co.uk/MarkToUMS/" onClick={() => {
                                    //e.preventDefault()
                                    fetch("https://rpt.ben-services.eu.org/api/gbw", {
                                        method: "POST",
                                        headers: {
                                            "Content-Type": "application/json"
                                        },
                                        body: JSON.stringify({
                                            uid: localStorage.getItem('uid'),
                                            action: "interaction",
                                            code: "1000_1"
                                        })
                                    })
                                    //window.open("https://services.portal.wjec.co.uk/MarkToUMS/")
                                }}>WJEC Portal UMS converter</a></p>
                                <p className="text-sm bottom-0 text-gray-500">Navigate to the desired paper, press CTRL+A, then CTRL+C</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="absolute bottom-0 w-full">
                    <div className="flex flex-col justify-center items-center ml-auto text-center pb-2">
                        <p className="text-sm bottom-0 text-gray-500">{branch}/{commit} - {window.location.hostname}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
