const EPOCH = 1669766400000;

export function generate_snowflake(){
    // snowflake format
    // timestamp - 42 bits
    // increment - 12 bits

    // timestamp
    const timestamp = Date.now() - EPOCH;
    const timestamp_bits = timestamp.toString(2).padStart(42, "0");

    // increment
    const increment = Math.floor(Math.random() * 4096);
    const increment_bits = increment.toString(2).padStart(12, "0");

    // combine
    const snowflake = timestamp_bits + increment_bits;

    // convert to decimal
    return parseInt(snowflake, 2);
}