import { experiments } from "./experiments"

export interface Data {
    level: string
    session: string
    year: number
    subject: string
    unit: number
    code: number
    grades: {
        grade: string
        ums: number
        raw: number
    }[]
    //data: string
    max_raw: number
    ums_to_raw: { [key: string]: number }
    raw_to_ums: { [key: string]: number }
}

export default function validate(data: string, existing_data: Data[]): Data | string {
    if(data.includes("GCSE")){
        return "Only GCE data is supported at this time"
    }
    try {

        if (data.split("\n")[3].trim().length === 4){
            // stupid chrome
            // remove lines 3-11
            data = data.split("\n").filter((_, i) => i < 3 || i > 11).join("\n")
        }
    } catch (e) {
        console.log(`Caught error removing lines 3-11`)
        console.error(e)
        if(experiments.includes("2024-06_help-page_1")){
            return "This data doesn't look right. Visit the help page for more information."
        }
        return "This data doesn't look right. Remember to copy the entire page using CTRL+A and CTRL+C"
    }
    
    ///(Year[\s]+Session[\s]+Brand[\s]+Subject[\s]+Option)[\n\r\s]*(?<level>[\w\s]+) (?<session>[\w]+) (?<year>[\d]+) (?<subject>[\w ]+) UNIT (?<unit>[\d]+) \((?<code>[\d]+)\)[\n\r\s]*(Grade)[\s]+([\w\d*]{1,5}[\s]+)+[\n\r\s]*(UMS Grade Boundary)[\s]+(?<ums>(([\d]{3})[\s]+?)+?)[\n\r\s]*(Raw Mark Grade Boundary)[\s]+(?<raw>(([\d]{3})[\s]+?)+?)[\n\r\s]*(What is a UMS\?)[\n\r\s]+(?<data>[\n\r\s\w]+)/
    const regex = /(Year[\s]+Session[\s]+Brand[\s]+Subject[\s]+Option)[\n\r\s]*(?<level>GC[s]{0,1}E) (?<session>[\w]+) (?<year>[\d]+) (?<subject>[\w ]+) UNIT (?<unit>[\d]+) \((?<code>[\d]+)\)[\n\r\s]*(Grade)[\s]+([\w]{1}[\s]+){6}[\n\r\s]*(UMS Grade Boundary)[\s]+(?<a>[\d]{3})[\s]+(?<b>[\d]{3})[\s]+(?<c>[\d]{3})[\s]+(?<d>[\d]{3})[\s]+(?<e>[\d]{3})[\s]+(?<u>[\d]{3})[\n\r\s]+(Raw Mark Grade Boundary)[\s]+(?<a_raw>[\d]{3})[\s]+(?<b_raw>[\d]{3})[\s]+(?<c_raw>[\d]{3})[\s]+(?<d_raw>[\d]{3})[\s]+(?<e_raw>[\d]{3})[\s]+(?<u_raw>[\d]{3})[\n\r\s]+(What is a UMS\?)[\n\r\s]+(?<data>[\n\r\s\w]+)/
    const match = regex.exec(data)
    if (!match) {
        if(experiments.includes("2024-06_help-page_1")){
            return "This data doesn't look right. Visit the help page for more information."
        }
        return "This data doesn't look right. Remember to copy the entire page using CTRL+A and CTRL+C"
    }

    match.groups = match.groups || {}

    if(existing_data[0] && (existing_data[0].subject !== match.groups!.subject || existing_data[0].unit !== parseInt(match.groups!.unit))){
        return `Comparisons can only be made between the same exam. The first exam was ${existing_data[0].subject} U${existing_data[0].unit} and this exam is ${match.groups.subject} U${match.groups.unit}`
    }

    if(existing_data.some((d) => d.year === parseInt(match.groups!.year))){
        return `This paper has already been added`
    }

    document.title = `${match.groups.subject} U${match.groups.unit}` 

    let conversion_data = match.groups.data

    conversion_data = conversion_data.replace(/[\n\r\s]+/g, ' ')

    conversion_data = conversion_data.replaceAll("RAW MARK UMS", "").trim().replaceAll("  ", " ")

    const ums_to_raw: { [key: string]: number } = {};

    const raw_to_ums: { [key: string]: number } = {};

    const conversion_data_array = conversion_data.split(" ")

    for (let i = 0; i < conversion_data_array.length; i += 2) {
        raw_to_ums[parseInt(conversion_data_array[i])] = parseInt(conversion_data_array[i + 1])
        ums_to_raw[parseInt(conversion_data_array[i + 1])] = parseInt(conversion_data_array[i])
    }

    for (let i = 0; i < Object.keys(ums_to_raw).length - 1; i++) {
        const keys = Object.keys(ums_to_raw)
        const key = keys[i]
        const next = keys[i + 1]

        if(parseInt(key) + 1 !== parseInt(next)){
            const diff = parseInt(next) - parseInt(key)
            for (let j = 1; j < diff; j++) {
                ums_to_raw[parseInt(key) + j] = ums_to_raw[key]
            }
            
        }
    }
    // set max ums to lowest raw
    const max_ums = parseInt(conversion_data_array.at(-1) as string)
    ums_to_raw[max_ums] = ums_to_raw[max_ums - 1] + 1
    
    const max_ums_raw = ums_to_raw[max_ums]

    const a_star = Math.round(max_ums * 0.9)

    const a_star_raw = ums_to_raw[a_star]

    const grades = []

    grades.push({
        grade: "MAX UMS",
        ums: max_ums,
        raw: max_ums_raw
    })

    grades.push({
        grade: "A*",
        ums: a_star,
        raw: a_star_raw
    })

    grades.push({
        grade: "A",
        ums: parseInt(match.groups.a),
        raw: parseInt(match.groups.a_raw)
    })

    grades.push({
        grade: "B",
        ums: parseInt(match.groups.b),
        raw: parseInt(match.groups.b_raw)
    })

    grades.push({
        grade: "C",
        ums: parseInt(match.groups.c),
        raw: parseInt(match.groups.c_raw)
    })

    grades.push({
        grade: "D",
        ums: parseInt(match.groups.d),
        raw: parseInt(match.groups.d_raw)
    })

    grades.push({
        grade: "E",
        ums: parseInt(match.groups.e),
        raw: parseInt(match.groups.e_raw)
    })

    grades.push({
        grade: "U",
        ums: parseInt(match.groups.u),
        raw: parseInt(match.groups.u_raw)
    })

    return {
        level: match.groups.level,
        session: match.groups.session,
        year: parseInt(match.groups.year),
        subject: match.groups.subject,
        unit: parseInt(match.groups.unit),
        code: parseInt(match.groups.code),
        grades,
        //data: conversion_data,
        max_raw: parseInt(Object.keys(raw_to_ums).at(-1) as string),
        ums_to_raw,
        raw_to_ums
    }
}