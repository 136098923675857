import React from 'react'
import ReactDOM from 'react-dom/client'
import App, { commit, branch } from './App.tsx'
import './index.css'
import * as Sentry from "@sentry/react";
import { generate_snowflake } from './data/account.ts';
import { datadogRum } from '@datadog/browser-rum';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Help from './Help.tsx';


let uid = localStorage.getItem('uid')
if (!uid) {
    uid = generate_snowflake().toString()
    localStorage.setItem('uid', uid)
}

const env = branch === 'staging' ? 'staging' : 'production'

datadogRum.init({
    applicationId: '71fd2c5f-8f60-4dc1-8a41-c65eb378a736',
    clientToken: 'pub23cfadc24f8bd417fd51522fcfb12f3d',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'us5.datadoghq.com',
    service: 'grades',
    version: commit,
    proxy: `/monitoring-proxy`, //NO TRAILING SLASH
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
});



Sentry.init({
    dsn: "https://1ef7943a78f30bad22c7f6a030de1adb@o1252795.ingest.sentry.io/4506905985548288",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    release: commit,
    // Performance Monitoring
    tunnel: "/error-reporting-proxy/",
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/grades.benja.eu.org/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend(event, _hint) {
        if (event.exception) {
            console.error(event)
            // make sure the exception has not been sent recently to avoid loops
            const last_exception_time = localStorage.getItem('lastExceptionTime');
            const last_exception = localStorage.getItem('lastException');
            if (last_exception === String(event.exception?.values![0].value && last_exception_time && (Date.now() - Number(last_exception_time)) < 1000 * 60 * 60)) {
                console.log(`Dropping duplicate event: ${event.exception?.values![0].value}`)
                return null;
            }
        }
        localStorage.setItem('lastException', String(event.exception?.values![0].value) || event.event_id!)
        localStorage.setItem('lastExceptionTime', String(Date.now()))
        event.user = event.user || {id: localStorage.getItem('uid') || 'unknown'}
        return event;
    },
  });

Sentry.setUser({id: uid})

datadogRum.setUser({id: uid})

const router = createBrowserRouter([
  {
    path: '/help',
    element: <Help />,
  },
  {
    path: '*',
    element: <App />,
  }
])

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
)
