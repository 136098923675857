import { ArrowBack } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

function Help() {
    const [email, setEmail] = useState("bWFpbHRvOmJlbkBiZW5qYS5ldS5vcmc=")
    useEffect(() => {
        setEmail(atob(email))
    }, [])
    document.title = "Usage Guide"
    
    return (
        <div>
            <h1 className="text-4xl text-center xl:text-left">Usage Guide</h1>
            <p className="mb-2">This guide will show you how to create your own visualisations for subjects not already included in the website.</p>
            <button className="flex items-center my-2 bg-indigo-600 hover:bg-indigo-700 rounded-lg px-4 py-2 text-white" onClick={() => {
                window.history.back()
            }}>
                <ArrowBack fontSize="small" />
                <p>Back</p>
            </button>
            <ol className="list-decimal list-inside marker:font-bold">

                <blockquote className="p-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <li className="list-item">Visit the WJEC Portal UMS converter</li>
                </blockquote>
                <p className="my-2">The first step is to get the data you want to input. <a target="_blank" className='text-blue-500 hover:text-blue-600 visited:text-purple-500' href="https://services.portal.wjec.co.uk/MarkToUMS/" onClick={() => {
                    //e.preventDefault()
                    fetch("https://rpt.ben-services.eu.org/api/gbw", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify({
                            uid: localStorage.getItem('uid'),
                            action: "interaction",
                            code: "1000_2"
                        })
                    })
                    //window.open("https://services.portal.wjec.co.uk/MarkToUMS/")
                }}>Click here</a> to get to the converter.</p>

                <p className="my-2">Once here, navigate to the paper you want by using the dropdowns at the top of the page. The page will look something like this:</p>
                <blockquote className="p-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <img src="/static/1.png" alt="An image showing the converter with dropdowns populated" />
                    <p className="italic text-sm mt-1">An image showing the converter with dropdowns populated</p>
                </blockquote>

                <blockquote className="p-4 mt-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <li className="list-item">Copy the entire page</li>
                </blockquote>
                <p className="my-2">Once you have the data you want, copy the entire page using CTRL+A and CTRL+C (or right click - copy).</p>
                <p className="my-2">Make sure <span className="font-bold">all</span> the text is highlighted blue before copying.</p>
                <blockquote className="p-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <img src="/static/2.png" alt="An image showing the entire page selected with the copy button being hovered over" />
                    <p className="italic text-sm mt-1">An image showing the entire page selected with the copy button being hovered over</p>
                </blockquote>

                <blockquote className="p-4 mt-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <li className="list-item">Go back to the main page and paste</li>
                </blockquote>
                <p className="my-2"><Link className="text-blue-500 hover:text-blue-600 visited:text-purple-500" to="/">Click here</Link> to visit the main page and paste the data in the text box (CTRL+V or right click).</p>

                <blockquote className="p-4 mt-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <li className="list-item">Repeat for any other years</li>
                </blockquote>
                <p className="my-2">If you want to compare papers from different years, repeat the process for the other papers.</p>
                <p className="my-2">The text box is now at the bottom of the page.</p>

                <blockquote className="p-4 mt-4 bg-zinc-900 border-l-4 border-zinc-700">
                    <li className="list-item">Happy comparing!</li>
                </blockquote>
                <p className="my-2">The system will automatically compare the papers and generate visualisations for you.</p>
                <p className="my-2">It will also calculate the A* grade boundary.</p>
                <p className="my-2"><span className="font-bold">Important:</span> if you want to save this visualisation for later, you need to press the save button and follow the instructions.</p>
                <p className="my-2 text-center"><a id="email" className="text-blue-500 hover:text-blue-600 visited:text-purple-500" href={email}>contact</a></p>
            </ol>
        </div>

    )
}

export default Help;
