import { useEffect, useRef, useState } from "react";
import validate, { Data } from "../data/validate";

interface EntryBoxProps {
    addData: (data: Data) => Promise<void>
    data: Data[]
}

// delete text after success
export function EntryBox(props: EntryBoxProps) {
    const [dataError, setDataError] = useState<String | null>(null)
    const dataRef = useRef<Data[]>()
    dataRef.current = props.data
    useEffect(() => {
        const pasteListener = (e: ClipboardEvent) => {
            const data = e.clipboardData?.getData('text')
            const res = validate(data!, dataRef.current ?? [])
            if (typeof res === 'object') {
                props.addData(res)
                setDataError(null)
            } else {
                setDataError(res)
            }
        }
        window.addEventListener('paste', pasteListener)
        return () => {
            window.removeEventListener('paste', pasteListener)
        }
    }, [])
    return (
        <div className="mb-auto">
            <label htmlFor="data" className="block text-sm font-medium leading-6 text-white">

            </label>
            <div className="relative mt-2 rounded-md shadow-sm">
                <textarea
                    name="data"
                    id="data"
                    rows={5}
                    className="block w-full rounded-md border-0 py-1.5 pl-2 mr-80 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Paste data here"
                />
                <p className="absolute top-0 right-0 px-2 py-1 text-xs leading-5 font-extrabold text-red-600 bg-red-200 rounded-md">
                    {dataError}
                </p>
            </div>
        </div>
    )
}

export default EntryBox