import { Disclosure } from "@headlessui/react";
import { RxChevronUp } from "react-icons/rx";


export function CommonQuestions() {
    return (
        <div className="w-full pt-8">
            <div className="w-full rounded-2xl">
                <Disclosure>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-600 px-4 py-2 text-left text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span>Where can I find the 2024 grade boundaries?</span>
                                <RxChevronUp
                                    className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-white">
                                <p className="">2024 grade boundaries will be available on the WJEC Portal for all users at 8am on the 15th of August for GCE and 8am on the 22nd of August for GCSE.</p>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-600 px-4 py-2 text-left text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span>What is UMS?</span>
                                <RxChevronUp
                                    className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-white">
                                <p className="">UMS stands for Uniform Mark Scale. It is a system used by exam boards to convert raw marks into a standardised scale that takes into account your performance compared to the other students. This allows for easy comparison between different papers and years.</p>
                                <p className="pt-2">For example, a student who achieves a low raw mark in a hard paper will get a similar UMS to a student who achieves a higher raw mark in an easier paper.</p>
                                <p className="pt-2">Some universities use UMS to make offers to students applying to competitive courses. A higher overall UMS score will make your application more competitive.</p>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-600 px-4 py-2 text-left text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span>What is max UMS?</span>
                                <RxChevronUp
                                    className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-white">
                                <p>From WJEC:</p>
                                <blockquote className="p-4 mt-2 bg-gray-900 border-l-4 border-gray-300">
                                    A capping process is sometimes employed to allow the better performing candidates
                                    (those who do not achieve the maximum raw mark, but do have a raw mark well
                                    above the minimum required for grade A) to be allocated the maximum uniform
                                    mark. This compensatory device is used when the minimum raw mark required for
                                    grade A is some way below the maximum raw mark for the paper.
                                </blockquote>
                                <p className="pt-2">This means that students will normally not be penalised for losing a few marks on a paper.</p>
                                <p className="pt-2">It is possible to achieve max UMS even if you lose a couple of raw marks.</p>
                                <p className="pt-2">It is not an official grade or award. It will not be shown on your qualification certificate. It may be shown on results slips and universities may take interest in it.</p>

                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Disclosure as="div" className="mt-2">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-indigo-600 px-4 py-2 text-left text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                <span>Is it possible to achieve an A* at paper level?</span>
                                <RxChevronUp
                                    className={`${open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pb-2 pt-4 text-white">
                                <p>No.</p>
                                <p className="pt-2">An A* grade is only awarded at the subject level at the end of the qualification (80% average UMS across all units and 90% average UMS across A2 units).</p>
                                <p className="pt-2">This tool shows an A* grade at paper level as 90% of the maximum UMS for the paper. It is only relevant for A2 units.</p>
                                <p className="pt-2">It is not an official grade and is only used as a reference.</p>
                                <p className="pt-2">From WJEC:</p>
                                <blockquote className="p-4 mt-2 bg-gray-900 border-l-4 border-gray-300">
                                    GCE A* is awarded at subject level only – there is no A* at unit level. A* is awarded to candidates who have achieved grade A in the overall A level qualification and who have also achieved 90% of the maximum uniform marks on the aggregate [total] of their A2 units.
                                </blockquote>

                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>
    )
}

export default CommonQuestions