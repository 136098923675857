import { useEffect, useRef, useState } from "react"
import { Data } from "../data/validate"
import DeleteModal from "./DeleteModal"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
interface LibraryData {
    struct_version: number,
    default_version: number,
    items: {
        id: string,
        name: string,
        updatedAt: Date | string,
        papers: Data[]
    }[]
}

async function fetch_data(id: string): Promise<Data[]> {
    return await (await fetch(`/sample_${id}.json`)).json()
}

async function get_default_data(): Promise<LibraryData> {
    const data: Data[][] = []
    // fetch all data simultaneously
    const promises = ["fm1", "fm2", "fm3", "fm4", "fm6", "cs3", "cs4", "cs5"].map((id) => fetch_data(id))
    const results = await Promise.all(promises)
    results.forEach((result) => {
        data.push(result)
    })
    return {
        struct_version: 0,
        default_version: 6,
        items: [
            {
                id: "165240342943258",
                name: "Further Maths U1 2018-2024",
                updatedAt: new Date(),
                papers: data[0]
            },
            {
                id: "165240344665963",
                name: "Further Maths U2 2018-2024",
                updatedAt: new Date(),
                papers: data[1]
            },
            {
                id: "165240345316772",
                name: "Further Maths U3 2018-2024",
                updatedAt: new Date(),
                papers: data[2]
            },
            {
                id: "165240345933504",
                name: "Further Maths U4 2019-2024",
                updatedAt: new Date(),
                papers: data[3]
            },
            {
                id: "165240346545704",
                name: "Further Maths U6 2019-2024",
                updatedAt: new Date(),
                papers: data[4]
            },     
            {
                id: "165240347159133",
                name: "Computer Science U3 2017-2024",
                updatedAt: new Date(),
                papers: data[5]
            },
            {
                id: "165240347689130",
                name: "Computer Science U4 2017-2024",
                updatedAt: new Date(),
                papers: data[6]
            },
            {
                id: "165240348253621",
                name: "Computer Science U5 2017-2024",
                updatedAt: new Date(),
                papers: data[7]
            }       
        ]
    }
}

const default_data_version = 6

interface LibraryProps {
    addData: (data: Data[]) => void
    setChangesMade: (changesMade: boolean) => void
}



export function Library(props: LibraryProps) {
    //TODO need to be able to save and load comparison
    // will need to change code to store the id
    // export/import library
    // rename comparisons
    const [libData, setLibData] = useState<LibraryData>({ struct_version: 0, default_version: 0, items: [] })
    const libDataRef = useRef<LibraryData>(libData)
    libDataRef.current = libData as LibraryData
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteModalName, setDeleteModalName] = useState("")
    const [deleteModalID, setDeleteModalID] = useState("")

    function save_library() {
        if(libDataRef.current.default_version !== -1){
            libDataRef.current.default_version = -1
            setLibData(libDataRef.current)
        }
        localStorage.setItem('library', JSON.stringify(libDataRef.current))
    }
    
    function delete_item(id: string) {
        console.log(`Deleting ${id}`)
        const new_items = libDataRef.current.items.filter((item: any) => item.id !== id)
        libDataRef.current.items = new_items
        setLibData(libDataRef.current)
        save_library()
    }

    useEffect(() => {
        async function run() {
            const data = localStorage.getItem('library')
            if (data) {
                const parsed = JSON.parse(data)
                if (parsed.default_version !== -1 && parsed.default_version < default_data_version) {
                    const default_data = await get_default_data()
                    localStorage.setItem('library', JSON.stringify(default_data))
                    setLibData(default_data)
                    return
                }
                setLibData(parsed)
            } else {
                const default_data = await get_default_data()
                localStorage.setItem('library', JSON.stringify(default_data))
                setLibData(default_data)
            }
        }
        run()
    }, [])

    return (
        <div className="flex flex-row gap-2 flex-wrap items-center justify-center">
            <DeleteModal isOpen={deleteModalOpen} closeModal={() => setDeleteModalOpen(false)} name={deleteModalName} id={deleteModalID} deleteItem={delete_item} />
            {libData.items.map((item) => {
                return (
                    <div className="bg-zinc-900 text-white rounded-lg px-10 py-6 my-2">
                        <h1 className="font-bold text-lg">{item.name}</h1>
                        <p>{item.papers.length} papers</p>
                        <div className="flex flex-row gap-2 justify-center items-center pt-2">
                            <button className="flex items-center bg-indigo-600 hover:bg-indigo-700 rounded-lg px-4 py-2 text-white" onClick={() => {
                                props.addData(item.papers)
                                props.setChangesMade(false)
                            }}>
                                <SearchOutlinedIcon fontSize="small" />
                                <p>View</p>
                            </button>
                            <button className="flex items-center bg-red-600 hover:bg-red-700 rounded-lg px-4 py-2 text-white" onClick={() => {
                                setDeleteModalOpen(true)
                                setDeleteModalName(item.name)
                                setDeleteModalID(item.id)
                            }}>
                                <DeleteOutlineIcon fontSize="small" />
                                <p>Delete</p>
                            </button>
                        </div>
                    </div>
                )
            })}
            {libData.items.length === 0 && 
                <div className="bg-zinc-900 text-white rounded-lg px-8 py-3 my-2">
                    <h1 className="font-bold text-lg">No saved items</h1>
                    <p>Save comparisons to your library to view them here.</p>
                    <h2 className="text-lg pt-2">Messed up?</h2>
                    <button className="bg-red-600 hover:bg-red-700 rounded-lg px-4 py-2 text-white mt-1" onClick={async () => {
                        const default_data = await get_default_data()
                        setLibData(default_data)
                        libDataRef.current = default_data
                        localStorage.setItem('library', JSON.stringify(default_data))
                    }}>Reset library</button>
                </div>
                }
        </div>
    )
}

export default Library